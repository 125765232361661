
class ZIDXListingPhotos implements ZIDXPage{
    constructor() {

    }
    getPageClass():string{
        return "zidxListingPhotosContainer";
    }
    getMatchURL():string{
        return "/listing-photos/";
    }
    render(){
        // console.log("listing photos");
    }
}